
export default {
  name: 'ImageIndex',

  props: {
    src: {
      type: String,
      default: ''
    },

    fallbackSrc: {
      type: String,
      default: ''
    },

    alt: {
      type: String,
      default: ''
    }
  }
}

